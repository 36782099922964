<template lang="pug">
.d-flex.align-items-center.domain-input
  om-select#domains.col.p-0(
    ref="domainInput"
    v-if="domains.length"
    :placeholder="placeholderText"
    size="large"
    :deletable="domainDeletable"
    searchable
    :options.sync="domains"
    optionKey="_id"
    optionText="domain"
    :value.sync="selectedDomain"
    @filter="selectedDomainCache = $event"
    @input="selectedDomain = $event"
    @remove="remove($event)"
    :error="$v.newDomain.$error"
    @addNew="onAddNewDomain($event)"
  )
  om-input#newDomain.col.p-0(
    v-else
    large
    :error="$v.newDomain.$error"
    :placeholder="placeholderText"
    v-model="newDomain"
    @enter="plainInputEnter"
    data-track="add domain name"
  )
</template>
<script>
  import GET_ACCOUNT_DOMAINS from '@/graphql/GetAccountDomains.gql';
  import GET_ACCOUNT_LIMITS from '@/graphql/GetAccountLimits.gql';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import REMOVE_DOMAIN from '@/graphql/RemoveDomain.gql';
  import GET_DOMAIN_USAGE_COUNT from '@/graphql/GetDomainUsageCount.gql';
  import ALL_CAMPAIGNS_COUNT from '@/graphql/AllCampaignsCount.gql';
  import { validateDomain, removeProtocolFromDomain, removeWWWFromDomain } from '@/util';
  import { getBrandedClassString } from '@/components/Elements/Button';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    props: {
      selected: {
        type: Object,
        required: false,
      },
      savedExternally: {
        type: Number,
      },
      isDynamicContent: {
        type: Boolean,
        required: false,
      },
      domainDeletable: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        selectedDomain: null,
        selectedDomainCache: null,
        newDomain: '',
        pathName: '',
        protocol: '',
        domains: [],
        domainLimit: null,
        campaignCount: 0,
        originalInput: '',
      };
    },

    computed: {
      savedFromExternally() {
        return this.savedExternally;
      },
      placeholderText() {
        return this.domains.length
          ? this.$t('newCampaignFlow.domain.select')
          : this.$t('newCampaignFlow.domain.placeholder');
      },
      domainLimitReached() {
        return this.domains.length >= this.domainLimit;
      },
      newDomainWithoutProtocol() {
        return removeProtocolFromDomain(this.newDomain).toLowerCase();
      },
      newDomainWithoutWWW() {
        return removeWWWFromDomain(this.newDomainWithoutProtocol).toLowerCase();
      },
      socialLoginHostedDomain() {
        return this.$store.state.account.login.socialLoginHostedDomain;
      },
    },

    validations: {
      newDomain: {
        isCool(v) {
          return validateDomain(v, this.isDynamicContent);
        },
      },
      selectedDomain: {
        isCool(v) {
          return validateDomain(v.domain) && v._id;
        },
      },
    },

    watch: {
      selectedDomain() {
        this.selectedDomainCache = null;
        this.$emit('selectedDomain', this.selectedDomain);
      },

      newDomain(v) {
        this.selectedDomainCache = v;
      },

      domains(v) {
        if (this.campaignCount > 0 && v.length === 0) {
          this.newDomain = '';
        }
      },

      savedExternally: {
        handler() {
          if (this.domains.length === 0) {
            this.addNewDomain();
          } else if (this.selectedDomainCache) {
            this.validateCampaignUrl(this.selectedDomainCache).then(this.addNewDomain());
          } else {
            this.$emit('saveEvent');
          }
        },
      },
    },
    async created() {
      await Promise.all([
        this.fetchCampaignCount(),
        this.fetchAccountLimits(),
        this.fetchDomains(),
      ]);

      const userDomainIds = this.domains.map((domain) => domain._id);
      if (this.selected && userDomainIds.includes(this.selected._id))
        this.selectedDomain = this.selected;

      if (this.domains.length === 1) {
        this.selectedDomain = this.domains[0];
      }

      if (this.campaignCount === 0 && this.domains.length === 0 && this.socialLoginHostedDomain) {
        this.newDomain = this.socialLoginHostedDomain;
      } else {
        this.newDomain = '';
      }
    },

    methods: {
      closeDomainInput() {
        if (this.$refs.domainInput?.$refs?.popper_domains?.showPopper) {
          this.$refs.domainInput?.$refs?.popper_domains.doClose();
          this.selectedDomainCache = null;
        }
      },
      async fetchAccountLimits() {
        const {
          data: { accountLimits },
        } = await this.$apollo.query({
          query: GET_ACCOUNT_LIMITS,
        });

        this.domainLimit = accountLimits.limits.domains;
      },

      async fetchDomains() {
        const {
          data: { accountDomains },
        } = await this.$apollo.query({
          query: GET_ACCOUNT_DOMAINS,
        });
        this.$store.dispatch('fetchDomains');

        this.domains = _clone(accountDomains).map((d) => {
          return { _id: d._id, domain: d.domain };
        });
      },

      async fetchCampaignCount() {
        const {
          data: {
            campaignData: { count },
          },
        } = await this.$apollo.query({
          query: ALL_CAMPAIGNS_COUNT,
        });

        this.campaignCount = count;
      },

      async addNewDomain() {
        this.$v.$touch();
        if (this.$v.newDomain.$invalid) {
          this.domainInvalidNotify();
          return;
        }

        const {
          data: { addDomain },
        } = await this.$apollo.mutate({
          mutation: ADD_DOMAIN,
          variables: {
            domain: this.newDomainWithoutProtocol,
          },
        });

        if (addDomain.message === 'domainAlreadyExists') {
          if (!this.isDynamicContent) {
            this.handleExistingDomain();
            if (this.savedFromExternally) {
              this.$emit('saveEvent');
            }
          } else {
            this.handleExistingDomainInDC();
            this.$emit('saveEvent', {
              selectedDomain: this.selectedDomain,
              pathName: this.pathName,
              originalInput: this.originalInput,
            });
          }

          this.closeDomainInput();
          await this.fetchDomains();
          this.$v.$reset();
          return;
        }

        if (addDomain._id) {
          this.selectedDomain = { _id: addDomain._id, domain: this.newDomain };
          this.$emit('selectedDomain', this.selectedDomain);
          this.validateCampaignUrl(this.newDomain);

          if (this.isDynamicContent || this.savedFromExternally) {
            this.$emit('saveEvent', {
              selectedDomain: this.selectedDomain,
              pathName: this.pathName,
              originalInput: this.originalInput,
            });
          }

          this.closeDomainInput();
          await this.fetchDomains();
          this.$v.$reset();
          this.$store.commit('setInsertCodeStatus', false);
        } else {
          this.domainInvalidNotify();
        }
      },

      domainInvalidNotify() {
        this.$notify({
          type: 'error',
          text: this.$t('newCampaignFlow.domain.invalid'),
        });
      },

      handleExistingDomain() {
        this.selectedDomain = this.domains.find(
          ({ domain }) => domain === this.newDomainWithoutProtocol,
        );

        this.$emit('selectedDomain', this.selectedDomain);
      },

      handleExistingDomainInDC() {
        this.selectedDomain = this.domains.find(
          ({ domain }) => domain === this.newDomainWithoutProtocol,
        );

        if (!this.selectedDomain) {
          this.selectedDomain = this.domains.find(
            ({ domain }) => domain === this.newDomainWithoutWWW,
          );
        }

        this.$emit('selectedDomain', this.selectedDomain);
      },

      async removeDomain(id) {
        const {
          data: { removeDomain },
        } = await this.$apollo.mutate({
          mutation: REMOVE_DOMAIN,
          variables: {
            domainId: id,
          },
        });

        if (removeDomain) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.domainRemoveSuccess'),
          });

          await this.fetchDomains();

          this.$emit('selectedDomain', null);
          this.$emit('refetch');

          if (this.domains.length === 0) {
            this.$store.commit('setInsertCodeStatus', false);
          }
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.domainRemoveError'),
          });
        }

        this.$modal.hide('dialog');
      },

      async remove({ _id }) {
        const id = _id;
        const {
          data: { domainUsageCount },
        } = await this.$apollo.query({
          query: GET_DOMAIN_USAGE_COUNT,
          variables: {
            domainId: id,
          },
        });

        if (domainUsageCount > 0) {
          this.$modal.show('dialog', {
            title: this.$t('domainUsed', { count: domainUsageCount }),
            buttons: [
              {
                title: this.$t('yes'),
                class: getBrandedClassString({ primary: true }, 'mr-3'),
                handler: () => {
                  this.removeDomain(id);
                },
              },
              {
                title: this.$t('cancel'),
                class: getBrandedClassString({ secondary: true }),
                default: true,
              },
            ],
          });
        } else {
          this.removeDomain(id);
        }
      },

      async onAddNewDomain(input) {
        await this.validateCampaignUrl(input);
        return this.addNewDomain();
      },

      async validateCampaignUrl(input) {
        this.originalInput = input;
        try {
          const rawUrl = /^(https?:\/\/|\/\/)/.test(input) ? input : `http://${input}`;
          const url = new URL(rawUrl);
          this.newDomain = url.hostname + url.pathname;
          this.protocol = url.protocol;
          this.pathName = url.pathname;
        } catch (e) {
          console.log(e);
          this.domainInvalidNotify();
          this.newDomain = '';
        }
      },

      async plainInputEnter() {
        await this.validateCampaignUrl(this.newDomain);
        this.addNewDomain();
      },
    },
  };
</script>
